import React from "react";
import SEO from "components/seo";
import AppContentContainer from "components/layouts/appLayout/AppContentContainer";

const FoundryPage = () => (
  <AppContentContainer spacing="extra">
    <SEO title="Characters" />
    <h1>Characters</h1>
    <p>
      This is where I'll list all the characters you have access to.... If I
      ever get around to it...
    </p>
  </AppContentContainer>
);

export default FoundryPage;
